import React, { Component } from "react";
import "../styles/CardsStyles.scss";
import Gurjot from "../assets/Staff/Gurjot.jpg";
import Amin from "../assets/Staff/Amin.jpg";
import Mehdi from "../assets/Staff/Mahdi.jpg";
import Woj from "../assets/Staff/Woj.jpg";
import Farshad from "../assets/Staff/Farshad.jpg";
import Banner from "../assets/Staff/banner.gif";
import ML from "../assets/Staff/ML.gif";
import Lennard from "../assets/Staff/Lennard.jpg";
import { Container, Row, Col } from "react-bootstrap";

// Components
class CardImg extends React.Component {
  render() {
    return (
      <div className="imgContainer">
        <img src={this.props.imgSrc} className="img imgStyles" />
      </div>
    );
  }
}
class CardAvatar extends React.Component {
  render() {
    return (
      <div className="infoContainer avatarContainerStyles">
        <img src={this.props.avatarSrc} className="avatarImgStyles" />
      </div>
    );
  }
}
class CardTitle extends React.Component {
  render() {
    return (
      <div className="titleDiv">
        <h1 id={this.props.targetId} className="title titleStyles">
          {this.props.title}
        </h1>
        <h4 className="subTitle subTitleStyles">{this.props.subTitle}</h4>
      </div>
    );
  }
}
class CardBio extends React.Component {
  render() {
    return (
      <div className="bioContainer bioContainerStyles">
        <p className="bio bioStyles">{this.props.bio}</p>
      </div>
    );
  }
}
class CardSocialIcons extends React.Component {
  render() {
    let linkdBtn;
    let emailBtn;
    if (this.props.linkedin !== "") {
      linkdBtn = (<span className="icons iconStyles">
      <a href={this.props.linkedin} target="_blank" rel="noreferrer">
        <i className="fa-brands fa-linkedin"></i>
      </a>
    </span>);
    } else {
      linkdBtn = (<span></span>);
    }
    if (this.props.email !== "") {
      emailBtn = (
        <span className="icons iconStyles">
          <a href={this.props.email}>
            <i className="fa-solid fa-envelope"></i>
          </a>
        </span>
      );
    } else {
      emailBtn = (<span></span>);
    }
    return (
      <div className="iconContainer iconsContainerStyles">
        {linkdBtn}
        {emailBtn}
      </div>
    );
  }
}
class CardBack extends React.Component {
  render() {
    return <div className="cardBack cardBackStyles"></div>;
  }
}

class Card extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      title: "Gurjot Singh",
      subTitle: "",
      bio: "Founder | Chief Executive Officer",
      direction: "forwards",
      linkedin: "https://www.linkedin.com/in/gsinghm",
      email: "mailto:g.singh@reachsoftware.ai",
    };
  }
  componentWillMount() {
    if (this.props.type === "mem6") {
      this.setState({
        title: "Lennard Drogendijk",
        subTitle: "",
        bio: "Executive Advisor",
        linkedin: "https://www.linkedin.com/in/lennarddrogendijk/",
        email: "",
      });
    } else if (this.props.type === "mem2") {
      this.setState({
        title: "Wojciech Boczarski",
        subTitle: "",
        bio: "Chief Technology Advisor",
        linkedin: "",
        email: "mailto:wojciech.boczarski@reachsoftware.ai",
      });
    } else if (this.props.type === "mem3") {
      this.setState({
        title: "Amin Dorastanian",
        subTitle: "",
        bio: "Chief A.I. Architect & Advisor",
        linkedin: "https://www.linkedin.com/in/amin-dorostanian-a8340353",
        email: "mailto:amin.dorastanian@reachsoftware.ai",
      });
    } else if (this.props.type === "mem5") {
      this.setState({
        title: "Farshad Nabizadeh",
        subTitle: "",
        bio: "Chief Technology Officer",
        email: "mailto:farshad@reachsoftware.ai",
        linkedin: "https://www.linkedin.com/in/farshad-nabizadeh-80908144",
      });
    } else if (this.props.type === "mem4") {
      this.setState({
        title: "Mahdi Massahi",
        subTitle: "",
        bio: "Machine-learning Engineer",
        email: "mailto:mahdi.massahi@reachsoftware.ai",
        linkedin: "https://www.linkedin.com/in/mahdi-massahi-196b3718b",
      });
    }
  }
  render() {
    return (
      <div className="flipperContainer">
        <div className="flipper">
          <div className="cardFront cardContainer cardContainerStyles">
            <CardImg imgSrc={this.props.imgSrc} />
            <CardAvatar avatarSrc={this.props.avatarSrc} />
            <CardTitle
              targetId={this.props.targetId}
              title={this.state.title}
              subTitle={this.state.subTitle}
            />
            <CardBio bio={this.state.bio} />
            <CardSocialIcons
              email={this.state.email}
              linkedin={this.state.linkedin}
            />
          </div>
          <div className="cardBack cardBackStyles">
            <img
              alt="Profile Pic"
              className="cardBackImg cardBackImgStyles"
              src={this.props.cardBackImgSrc}
            />
          </div>
        </div>
      </div>
    );
  }
}
class CardContainer extends React.Component {
  render() {
    return (
      <>
        <Col className="CardCol">
          <Card
            imgSrc={Banner}
            avatarSrc={Gurjot}
            cardBackImgSrc={Gurjot}
            targetId="navi"
          />
        </Col>

        <Col className="CardCol">
          <Card
            type="mem6"
            imgSrc={Banner}
            avatarSrc={Lennard}
            cardBackImgSrc={Lennard}
          />
        </Col>
        <Col className="CardCol">
          <Card
            type="mem5"
            imgSrc={Banner}
            avatarSrc={Farshad}
            cardBackImgSrc={Farshad}
          />
        </Col>
        <Col className="CardCol">
          <Card
            type="mem2"
            imgSrc={Banner}
            avatarSrc={Woj}
            cardBackImgSrc={Woj}
          />
        </Col>
        <Col className="CardCol">
          <Card
            type="mem3"
            imgSrc={Banner}
            avatarSrc={Amin}
            cardBackImgSrc={Amin}
          />
        </Col>
        <Col className="CardCol">
          <Card
            type="mem4"
            imgSrc={Banner}
            avatarSrc={Mehdi}
            cardBackImgSrc={Mehdi}
          />
        </Col>
        
      </>
    );
  }
}

export default CardContainer;
