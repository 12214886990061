export const NewsData = [
  {
    title: "",
    date: "June 2024",
    picture: "/Images/News/1717591007097.jpg",
    content:
      'The trademark for Reach A.I. Software <span style="font-size: 0.7rem; vertical-align: super; display: inline;">®</span> is officially registered with the <a href="https://www.boip.int" target="_blank" rel="noreferrer"> Benelux Office for Intellectual Property </a>',
    link: {
      url: "https://www.linkedin.com/posts/activity-7204098835525050371-5Ed2/",
      text: "Read more",
    },
    category: {
      tag: "collaboration",
      color: "#018f69",
    },
  },
    {
    title: "",
    date: "March 2024",
    picture: "/Images/News/1709820490481.jpg",
    content:
      'REACH A.I. Software <span style="font-size: 0.7rem; vertical-align: super; display: inline;">®</span> is now officially accessible on all devices: iOS, MacOS, Windows & Android',
    link: {
      url: "https://www.linkedin.com/feed/update/urn:li:activity:7171506928584536064/",
      text: "Read more",
    },
    category: {
      tag: "collaboration",
      color: "#018f69",
    },
  },
  {
    title: "",
    date: "November 2023",
    picture: "/Images/News/News1Banner.jpg",
    content:
      'Reach A.I. Software B.V. signs collaboration with <a href="https://www.rug.nl/" target="_blank" rel="noreferrer"> University of Groningen </a> to research into and develop an A.I. Assistant for <a href="https://www.linkedin.com/company/wubbo-ockels-school/" target="_blank" rel="noreferrer"> Wubbo Ockels School </a> and <a href="https://www.rug.nl/wubbo-ockels-school/research/hydrogen-centre/collaboration/?lang=en" target="_blank" rel="noreferrer"> Hydrogen Valley Campus Europe </a>.',
    link: {
      url: "https://www.linkedin.com/feed/update/urn:li:activity:7153335917800284162/",
      text: "Read more",
    },
    category: {
      tag: "collaboration",
      color: "#018f69",
    },
  },
  {
    title: "",
    date: "June 2023",
    content:
      "Algorithm development successfully completed. Algorithm goes live via integration with Mobile Applications.",
      category: {
      tag: "Announcement",
      color: "#2196f3",
    },
  },
  {
    title: "",
    date: "May 2023",
    content:
      "MIT Research & Development Grant awarded for research into implementation of Natural language processing into Reach A.I. Software platform.\n Grant ID: MITH23245",
    category: {
      tag: "Grant",
      color: "#a01d1d",
    },
  },
  {
    title: "",
    date: "March 2023",
    content:
      "First Angel Investor onboarded; Pre-Seed valuation established.",
    
    category: {
      tag: "Announcement",
      color: "#2196f3",
    },
    
  },
  {
    title: "",
    date: "November 2022",
    picture: "/Images/News/News2Banner.jpg",
    content:
      "Beta Mobile Application goes live on Android & iOS App Stores",
    link: {
      url: "https://www.linkedin.com/feed/update/urn:li:activity:7153332382501134336/",
      text: "Read more",
    },
    category: {
      tag: "Announcement",
      color: "#2196f3",
    },
  },
  {
    title: "",
    date: "May 2022",
    content:
      "MIT Research & Development Grant awarded for research into co-development IDE's, their implementation and feasibility.\n Grant ID: MITH22107", 
    category: {
      tag: "Grant",
      color: "#a01d1d",
    },
    
  },
  {
    title: "",
    date: "March 2022",
    content:
      "ERDF VIA Grant Awarded for development of machine-learning algorithm.\n Grant ID: VIP21S069",
    
    category: {
      tag: "Grant",
      color: "#a01d1d",
    },
    
  },
  {
    title: "",
    date: "November 2021",
    content:
      "MKB Innovatie-Matrix Voucher awarded.",
    category: {
      tag: "Announcement",
      color: "#2196f3",
    },
    
  },
  {
    title: "",
    date: "March 2021",
    content:
      'Gurjot Singh & team create software proof-of-concept and begin collaboration with <a href="https://inqubator.nl/"> Inqubator Leeuwarden. </a>',
    category: {
      tag: "Birthday",
      color: "#b48400",
    },
    
  },
];
