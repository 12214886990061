import { motion, useViewportScroll, useTransform } from "framer-motion";
import React, { useState } from "react";
import SNN from "../assets/SNNFC.png";
import RUG from "../assets/RUG.png";
import BOIP from "../assets/boiplogo.png";

const TimelineItem = ({ data, idx }) => {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const itemStyle = {
    transform: isHovered ? 'scale(1.05)' : 'scale(1)',
    transitionTimingFunction: 'ease-in',
    transition: 'transform 0.2s ease-in-out',
    
  };

  const { scrollY } = useViewportScroll();
  const glowTop = 100 + useTransform(scrollY, (value) => Math.min(value, 100));

  return (

<>
    <div
      className={`timeline-item ${idx}`}
      style={itemStyle}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div className="timeline-item-content">
        {/* <span className="tag" style={{ background: data.category.color }}>
          {data.category.tag}
        </span> */}
        <time className="SubMoto dynamicFont">{data.date}</time>
        {data.hasOwnProperty("picture") ? (
          <img src={data.picture} alt={data.title} className="timeline-banner" />
        ) : (
          <></>
        )}
        <p className="datacontent" dangerouslySetInnerHTML={{ __html: data.content }}></p>
        
        {data.date === "June 2024" && (
          <a href="https://www.boip.int" target="_blank" rel="noreferrer" className="NewsLogoLink">
          <img alt="BOIP Logo" src={BOIP} className="NewsLogoImgBOIP" /></a>
        )}

        {data.date === "November 2023" && (
          <a href="https://www.rug.nl/" target="_blank" rel="noreferrer" className="NewsLogoLink">
          <img alt="RUG Logo" src={RUG} className="NewsLogoImgUni" /></a>
        )}

        {data.category.tag === "Grant" && (
          <a href="https://www.snn.nl/" target="_blank" rel="noreferrer" className="NewsLogoLink">
          <img alt="SNN Logo" src={SNN} className="NewsLogoImg" /></a>
        )}

        {data.link && (
          <a href={data.link.url} target="_blank" rel="noopener noreferrer">
            {data.link.text}
          </a>
        )}
        <span className={`circle ${isHovered ? 'no-scale' : ''}`} />
      </div>
    </div>
    
    </>
  );
};

export default TimelineItem;
